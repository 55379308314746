import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  makeStyles
} from "@material-ui/core";
import headerImage from "../src/assets/neodisha.png";
import apkFile from "../src/assets/NeoDIShA_Test_V1.0.5.apk";
import { saveAs } from "file-saver";
import {Helmet} from "react-helmet";

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: "4rem auto"
  },
  list: {
    padding: "0 1.5rem"
  },
  appBar: {
    marginBottom: "2rem",
    backgroundColor: "#FFFFFF",
  },
  logo: {
    marginRight: "1rem",
    height: "40px"
  },
  title: {
    flexGrow: 1
  }
});

export default function DownloadScreen() {
  const classes = useStyles();

  function handleDownload() {
    fetch(apkFile)
      .then((response) => response.blob())
      .then((blob) =>
        saveAs(blob, "NeoDIShA_V1.0.5.apk", )
      )
      .catch((error) => console.error(error));
  }


  return (
    
    <><AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <img src={headerImage} alt="logo" className={classes.logo}/>
        {/* Add more components or buttons to the toolbar as needed */}
      </Toolbar>
    </AppBar>
    <div className="DownloadScreen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>NeoDIShA-update</title>
                <link rel="canonical" href="https://neodisha-app.neophyte.live/" />
            </Helmet>
            ...
        </div>
    <Card className={classes.root} elevation={2}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h1">
            NeoDIShA Application
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="h2"
            paragraph
          >
            Download your new version 1.0.5 by clicking Download File.
          </Typography>
        </CardContent>

        <CardActions>
          <Button variant="contained" size="medium" disableElevation onClick={handleDownload}>Download File</Button>
        </CardActions>
      </Card></>
  );
}
